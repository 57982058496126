import { Fade } from "react-reveal";

const Experience = () => {
    return (
          <div className="pt-24 grid grid-cols-1 md:grid-cols-2 gap-8 pb-40 border-b-2 border-gray-900">
            <div>
                <Fade right distance="3rem">
                    <h4 className="font-bold text-xl">Studios / Agencies</h4>
                    <p className="pb-4 text-slate-200">I've worked with:</p>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li>DVXD</li>
                        <li>Playground Inc</li>
                        <li>Jam3</li>
                        <li>Array Of Stars</li>
                        <li>Entertainment One</li>
                        <li>Secret Location</li>
                        <li>Tribal DDB</li>
                        <li>Grip Limited</li>
                        <li>TVOntario</li>
                        <li>One Pixel Off</li>
                    </ul>
                </Fade>
                <Fade right distance="3rem">
                    <h4 className="pt-12 font-bold text-xl">Clients</h4>
                    <p className="pb-4 text-slate-200">I've worked with:</p>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li>Bank of Montreal</li>
                        <li>Disney</li>
                        <li>Entertainment One</li>
                        <li>Guardian UK</li>
                        <li>History Channel</li>
                        <li>Indigo</li>
                        <li>Honda</li>
                        <li>Moosehead Breweries</li>
                        <li>National Film Board of Canada</li>
                        <li>Qaggiavuut</li>
                        <li>RBC Future Makers</li>
                        <li>Samsung</li>
                        <li>Volkswagen</li>
                        <li>Wander The Resort</li>
                        <li>&nbsp;</li>
                        <li className="font-bold text-slate-200">And many more...</li>
                    </ul>
                </Fade>
            </div>
            <div className="pr-2">
                <Fade right distance="3rem">
                    <h4 className="font-bold text-xl">Technical Skills & Experience</h4>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li className="text-slate-200 pt-6 font-bold">Foundations</li>
                        <li>Structural, Functional, and Object-oriented design patterns.</li>
                        <li className="text-slate-200 pt-6 font-bold">Languages</li>
                        <li>JavaScript (ES6, Typescript),</li>
                        <li>Python,</li>
                        <li>PHP.</li>
                        <li className="text-slate-200 pt-6 font-bold">Frontend</li>
                        <li>Modern Javascript frameworks</li> 
                        <li>(React Native, React, Angular, Vue, etc.).</li>
                        <li className="text-slate-200 pt-6 font-bold">Backend</li>
                        <li>Relational and non-relational database design (SQL, PostgreSQL, MongoDB),</li>
                        <li>Node, Express,</li>
                        <li>RESTful and GraphQL APIs.</li>
                        <li className="text-slate-200 pt-6 font-bold">Cloud & DevOps</li>
                        <li>Google Cloud Platform (GCP),</li> 
                        <li>Amazon Web Services (AWS),</li>
                        <li>CI/CD Pipeline.,</li>
                        <li className="text-slate-200 pt-6 font-bold">Tooling & Testing</li>
                        <li>Version Control, Package Managers,</li> 
                        <li>Audit and testing suites (Jest, Lighthouse).</li>
                        <li className="text-slate-200 pt-6 font-bold">Creative Web Technologies</li>
                        <li>GLSL Shaders, Three.js, D3.js.</li>
                        <li className="text-slate-200 pt-6 font-bold">Web Animatics</li>
                        <li>SVG, CSS, JS and HTML Canvas.</li>
                        <li className="text-slate-200 pt-6 font-bold">Mobile Games</li>
                        <li>Entity Component Systems (ECS), Phaser, Pixi.</li>
                        <li className="text-slate-200 pt-6 font-bold">eCommerce</li>
                        <li>Shopify custom themes & Headless CMS,</li>
                        <li>Wordpress (Gutenberg).</li>
                        <li className="text-slate-200 pt-6 font-bold">Generative AI</li>
                        <li>Retrieval-Augmented Generation (RAG), Advanced Prompt Engineering.</li>
                    </ul>
                </Fade>
            </div>
        </div>
    )
}

export default Experience;