import Fade from 'react-reveal/Fade';

const Profile = () => {
    return (
        <section>
            <Fade right distance="3rem">
                <p className="text-lg pb-8">
                    Senior Full-stack Developer with 10+ years of experience creating mobile apps, enterprise websites, and bespoke microsites for small businesses. Specialized in modern tech stacks, APIs, and web services. Dedicated to delivering performant, accessible, visually expressive presentation layers and secure, scalable backends.
                </p>
                <p className="text-lg">
                    A creative, adaptable problem-solver with a strong ethical mindset.
                </p>
            </Fade>
        </section>
    )
};

export default Profile;